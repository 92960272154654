import { useTheme } from "@mui/material/styles";
import { Box, AppBar, Toolbar, Container, BoxProps, useMediaQuery } from "@mui/material";
import useOffSetTop from "../../../fe-ui/hooks/use-offset-top";
import { bgBlur } from "../../../fe-ui/utils/cssStyles";
import { HEADER } from "../../../config-global";
import { ContactsSelector, Logo, LanguagePopover } from "../../components";
// import NavDesktop from "./nav/desktop";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { uiInitialLogoDarkForced } from "../../../state/ui-state";
import dataTestIds from "../../../utils/data-test-ids";
import NavMobile from "./nav/mobile";
import { NavItemProps } from "./nav/types";

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: "auto",
        borderRadius: "50%",
        position: "absolute",
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}

type Props = {
  renderHeaderContent?: () => ReactNode;
  enableMobileNav?: boolean;
  mobileNavItems?: NavItemProps[];
};

export default function Header({ renderHeaderContent, enableMobileNav, mobileNavItems }: Props) {
  const theme = useTheme();

  const { pathname } = useRouter();
  const isHome = pathname === "/";
  const isContactsPage = pathname === "/contact-us";
  const isDarkForced = useRecoilValue(uiInitialLogoDarkForced);
  const isOffset = useOffSetTop(10);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar id={"application-header"} color={"transparent"} sx={{ boxShadow: 0 }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(["height", "background-color"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: "flex", alignItems: "center" }}>
          <Logo isDark={isDarkForced || !!isOffset || !isHome} />

          {(isHome || isContactsPage) && (
            <Box
              ml={2}
              sx={{
                color: isContactsPage || isDarkForced || isOffset ? "#223A6D" : theme.palette.background.default,
                fontWeight: 600,
                fontSize: [12, 14, 16],
              }}
            >
              {"Official Sea Breeze Booking"}
            </Box>
          )}

          <Box sx={{ flexGrow: 1 }}>{renderHeaderContent?.()}</Box>

          {(isHome || isContactsPage) && (
            <ContactsSelector
              isOffset={isOffset}
              dataTestIds={{
                button: dataTestIds.CONTACTS_SELECTOR.BUTTON,
                menuItem: {
                  tel: dataTestIds.CONTACTS_SELECTOR.MENU_ITEM.TEL,
                  tg: dataTestIds.CONTACTS_SELECTOR.MENU_ITEM.TG,
                  wa: dataTestIds.CONTACTS_SELECTOR.MENU_ITEM.WA,
                },
              }}
              withTelegram={false}
            />
          )}
          <LanguagePopover
            isOffset={isOffset}
            dataTestIds={{
              button: dataTestIds.LANGUAGE_POPOVER.BUTTON,
              menuItem: {
                az: dataTestIds.LANGUAGE_POPOVER.MENU_ITEM.AZ,
                ru: dataTestIds.LANGUAGE_POPOVER.MENU_ITEM.RU,
                en: dataTestIds.LANGUAGE_POPOVER.MENU_ITEM.EN,
              },
            }}
          />

          {/* <IconButton
            onClick={() => {}}
            sx={{
              display: {
                xs: "none",
                md: "inline-flex"
              },
              ml: 1,
              width: 30,
              height: 30,
              boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
              borderRadius: "15px",
              backgroundColor: theme.palette.background.default,
              "&:hover": {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.default
              },
              zIndex: 1,
              ...(isOffset && {
                color: "text.primary"
              })
            }}
          >
            <Iconify icon={"fa6-solid:manat-sign"} />
          </IconButton> */}
          {enableMobileNav && isMobile && <NavMobile isOffset={isOffset} data={mobileNavItems ?? []} />}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}
