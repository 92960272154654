import dynamic from "next/dynamic";
import { Box } from "@mui/material";
import { ReactNode } from "react";

import Footer from "./Footer";
import Header from "./Header";
import { HeaderBannerListResponseDataItem } from "../../../api/content-client/service";
import { ContactWidgetLazy } from "../../widgets/ContactWidget/ContactWidgetLazy";

const CookieBanner = dynamic(
  () => import("../../../fe-ui/blocks/CookieBanner/CookieBanner").then((x) => x.CookieBanner),
  {
    ssr: false,
  }
);

const MainPromoBanner = dynamic(() => import("../../components/main/MainPromo").then((x) => x.MainPromoBanner), {
  ssr: false,
});

type Props = {
  children?: ReactNode;
  renderHeaderContent?: () => ReactNode;
  banners?: HeaderBannerListResponseDataItem[];
};

export default function MainLayout({ children, renderHeaderContent, banners }: Props) {
  const hasBanners = banners && banners.length > 0;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1 }}>
      <Header renderHeaderContent={renderHeaderContent} />
      {hasBanners ? (
        <Box sx={{
          // Fallback to aspectRatio: "750 / 944", - Safari issue
          "&:before": {
            float: "left",
            paddingTop: {
              xs: `${94400 / 750}%`,
              sm: "initial",
            },
            content: "''",
          },
          "&:after": {
            display: "block",
            content: "''",
            clear: "both",
          },
          // End of fallback
          flexShrink: 0,
          minHeight: {
            sm: 260,
            md: 348,
            lg: 456,
            xl: 500,
          },
        }}>
          <MainPromoBanner banners={banners} />
        </Box>
      ) : null}
      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          ...{
            pt: { xs: 6, sm: 11 },
          },
          // ...(!isHome && {
          //   pt: { xs: 8, md: 11 }
          // })
        }}
      >
        {children}
      </Box>

      <Footer />

      <CookieBanner />
      {false && <ContactWidgetLazy type="speeddial" />}
    </Box>
  );
}
