import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { List, Drawer, IconButton, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NAV } from "../../../../../config-global";
import { Logo, Iconify } from "../../../../components/";
import Scrollbar from "../../../../components/scrollbar/Scrollbar";
import { NavProps } from "../types";
import NavList from "./NavList";

export default function NavMobile({ isOffset, data }: NavProps) {
  const { pathname } = useRouter();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          ml: 1,
          width: 30,
          height: 30,
          boxShadow: "-2px 8px 20px 2px rgba(145, 158, 171, 0.4)",
          borderRadius: "15px",
          backgroundColor: theme.palette.background.default,
          zIndex: 1,
          ...(isOffset && {
            color: "text.primary"
          })
        }}
      >
        <Iconify icon={"eva:menu-fill"} />
      </IconButton>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE
          }
        }}
      >
        <Scrollbar>
          <Box p={2}>
            <Logo isDark />
          </Box>
          <List component={"nav"} disablePadding>
            {data.map(link => (
              <NavList key={link.title} item={link} />
            ))}
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}
